.header-title {
  display: flex;
  align-items: center;

  h4 {
    font-size: 1.375rem;
    font-weight: 600;
    margin-right: 1.5rem;
  }
}
