// Main Header
.mainHeader {
  background-color: #f6f9fa;
  padding: 15px 0px;

  .myntra {
    text-align: center;
    font-size: 15px;
    color: #2264dc;
    font-weight: bold;
  }

  .headerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .loginBtn {
    background-color: #2264dc;
    color: #ffffff;
    padding: 10px 30px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
