@import "../../styles/main.scss";

/*------------------------------------
 User Info CSS
------------------------------------*/
.user-info {
  position: relative;

  h3 {
    font-size: 1.375rem;
    font-weight: 500;
  }

  .info-box {
    background-color: $gray-color;
    padding: 0.625rem 1rem;
    border-radius: 1rem;
    transition: all 300ms;
    height: 100%;

    h6 {
      font-size: 1rem;
    }

    span {
      font-size: 0.75rem;
      color: $light-black;
    }

    .info-detail {
      @include flex-space-between;
    }
  }

  .user-id {
    background-color: $gray-color;
    display: inline-block;
    font-size: 0.75rem;
    padding: 4px 10px;
    border-radius: 12px;
    margin-top: 10px;

    svg {
      position: relative;
      top: 1px;
    }

    span {
      margin-left: 5px;
      color: $light-black;
    }
  }

  .bottom-info {
    border-top: 2px solid $gray-color;
    margin-top: 1rem;
    padding-top: 0.625rem;
    font-size: 0.938rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 16px;
    padding-right: 16px;

    span {
      margin-left: 4px;
    }
  }
}
