@import "../../styles/main.scss";

/*--------------------------------------
 Home.module.scss
--------------------------------------*/
.agreement {
  position: relative;
  background-color: #ffffff;
  padding: 20px 50px 30px 50px;
  border-radius: 12px;
  margin-bottom: 15px;

  ul,
  ol {
    margin: 0;
    padding: 0;
  }
}
