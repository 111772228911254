// _mixins.scss

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
