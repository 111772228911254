@import "../../styles/main.scss";

// Auth.module.scss

.auth__styles {
  position: relative;
  padding: 2rem 0;

  .box {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }

  .form {
    width: 50%;
    padding: 3rem;

    img {
      width: 160px;
      margin-bottom: 15px;
    }

    h2 {
      font-weight: bold;
      font-size: 1.625rem;
      margin-bottom: 10px;
    }
  }

  .images {
    width: 50%;
  }
}
