// QRCode
.pdfContainer {
  padding: 20px;
  position: relative;

  .qrCodeImage {
    width: 100%;
    height: auto;
    display: block;
  }

  .qrCode {
    position: absolute;
    top: 336px;
    left: 267px;
    width: 260px;
    height: 260px;
  }

  .utmCode {
    position: absolute;
    top: 602px;
    left: 0;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
  }
}
