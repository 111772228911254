@import "../../styles/main.scss";

// Header.scss

.mantra {
  text-align: center;
  color: $secondary-color;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.header__styles {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  padding: 14px 16px;
  border-radius: 12px;
  margin-bottom: 15px;

  .search {
    position: relative;
    display: flex;
    align-items: center;

    input {
      border: none;
      padding: 13px;
      background-color: $gray-color;
      border-radius: 12px;
      width: 400px;
      font-size: 15px;

      &:focus {
        border: none;
        outline: none;
      }
    }

    select {
      position: absolute;
      right: 54px;
      top: 12px;
      border: none;
      background: none;

      &:focus {
        border: none;
        outline: none;
      }
    }

    button {
      background-color: $secondary-color;
      border: none;
      color: #ffffff;
      font-size: 22px;
      width: 40px;
      height: 40px;
      border-radius: 14px;
      margin-left: 10px;
      @include center-content;
    }
  }

  .user_info {
    @include flex-space-between;
  }

  .notification {
    width: 38px;
    height: 38px;
    background-color: $gray-color;
    margin-right: 20px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    @include center-content;
    display: none;

    & > div {
      line-height: 100%;
    }

    .dot {
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: #f22b37;
      border-radius: 50%;
      right: 10px;
      top: 8px;
    }
  }

  .user_name {
    margin-left: 8px;
    margin-right: 10px;

    h6 {
      font-size: 0.938rem;
      line-height: 100%;
      margin-bottom: 2px;
    }

    .adminId {
      background-color: $gray-color;
      font-size: 11px;
      padding: 2px 10px;
      border-radius: 15px;
      display: inline-block;
    }
  }

  .logout {
    cursor: pointer;
    line-height: 100%;
  }
}
