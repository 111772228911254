// Footer
.footer {
  background-color: #000023;
  padding: 60px 0 40px 0;

  .footerInner {
    h3 {
      font-size: 20px;
      color: #ffffff;
      margin-bottom: 25px;
      font-weight: 500;
    }

    .footerLogo {
      margin-bottom: 25px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    a {
      display: block;
      font-size: 14px;
      margin: 12px 0;
      color: #828282;
      transition: all 300ms;

      &:hover {
        color: #ffffff;
      }
    }

    p {
      font-size: 14px;
      margin: 12px 0;
      color: #828282;
    }

    .socialLinks {
      display: flex;

      li {
        margin-right: 15px;
      }
    }
  }

  .copyright {
    font-size: 13px;
    color: #828282;
    padding: 0px 20px 10px 20px;
    text-align: center;
  }

  .contactsList {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 10px;
  }
}
