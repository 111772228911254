// CRMDSAUserDetails.module.scss

.tabs {
  background-color: #e3eaf6;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  div {
    flex: 1;
    text-align: center;
    color: #4e4e4e;
    padding: 12px 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .active {
    background-color: #ffffff;
    color: #1f1f1f;
    font-weight: 600;
  }
}
