@import "../../../../styles/base/breakpoints";

// Banner
.banner {
  background-color: #f6f9fa;
  position: relative;

  .bannerContent {
    padding: 70px 0;
  }

  h1 {
    font-size: 56px;
    font-weight: bold;
    color: #000023;
    line-height: 76px;
    margin-bottom: 50px;

    @include respond-max($lg) {
      font-size: 40px;
      line-height: 125%;
    }

    span {
      color: #2264dc;
    }
  }

  .list {
    margin: 15px 0;
    display: flex;
    align-items: center;

    .icons {
      width: 30px;
      height: 30px;
      background: rgba(90, 140, 255, 0.1);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .listContent {
      color: #000023;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .bannerForm {
    background-repeat: no-repeat;
    padding: 70px 80px;
    background-size: cover;
    background-position: center left;
    position: absolute;
    width: 50%;
    right: 0;

    @include respond-max($lg) {
      position: relative;
      width: auto;
      padding: 30px;
    }
  }

  .innerForm {
    background-color: #ffffff;
    padding: 30px 60px;

    @include respond-max($lg) {
      padding: 25px;
    }

    h2 {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}
