@import "../../../../styles/base/breakpoints";

// FAQs
.faqStyles {
  position: relative;
  padding: 100px 0 120px 0;
  background-color: #f6f9fa;

  h2 {
    text-align: left;
  }

  p {
    text-align: left;
  }

  .faqList {
    @include respond-min($lg) {
      padding: 0 0 0 50px;
    }
  }
}
