// _typography.scss

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
}
