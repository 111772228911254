/*------------------------------------
 Drawer CSS
------------------------------------*/
.app__drawer {
  position: fixed;
  background-color: $secondary-color;
  height: 100%;
  width: 240px;

  .logo {
    padding: 1rem 2rem;
  }

  li {
    display: block;
  }

  li span {
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: normal;
  }

  a {
    display: block;
  }

  .active {
    background-color: #f6f6f6;

    span {
      color: #3c81f6;
    }
  }

  div {
    display: block;
  }
}
