@import "../../../../styles/base/breakpoints";

// Partners
.partnerStyles {
  padding: 100px 0 150px 0;
  background: #e6eefb;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 50%;
    left: 0;
    height: 50px;
    background: #e6eefb;
    top: -50px;
    border-top-right-radius: 50px;
  }

  &::after {
    position: absolute;
    content: "";
    width: 50%;
    right: 0;
    height: 50px;
    background: #e6eefb;
    top: -50px;
    border-top-left-radius: 50px;
  }

  .partners {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    margin: 15px 0;

    &:hover img {
      transform: scale(1.2);
    }
  }

  img {
    transition: all 300ms;
    transform: scale(1);
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 50px;
    color: #ffffff;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.2);
    transform: all 300ms;
    z-index: 999;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
