$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin respond-max($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin respond-min($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
