@import "../../styles/main.scss";

/*--------------------------------------
 Home.module.scss
--------------------------------------*/
.home__page {
  position: relative;
  margin-bottom: 20px;

  .leadCard {
    background-color: $gray-color;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    height: 100%;

    h6 {
      font-size: 1rem;
    }

    a {
      color: $light-black;
      font-size: 0.75rem;
    }

    .link {
      text-decoration: underline;
    }

    & > div {
      @include flex-space-between;
    }

    .utm {
      @include flex-space-between;
    }

    .utmLink {
      background-color: #ffffff;
      padding: 1px 5px;
      border-radius: 1rem;

      @include flex-space-between;
    }

    .icon {
      position: relative;
      top: 1px;
      margin-left: 2px;
      cursor: pointer;
    }

    .viewDocument {
      margin-top: 4px;
    }
  }

  .dsaPerformance {
    background-color: $gray-color;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    transition: all 300ms;
    border: 1px solid $gray-color;

    &:hover {
      border: 1px solid rgba(60, 129, 246, 1);
      background: rgba(60, 129, 246, 0.1);
    }

    h6 {
      font-size: 1rem;
    }

    p {
      color: $light-black;
      font-size: 0.75rem;
    }

    &:hover h6,
    &:hover p {
      color: $secondary-color;
    }
  }
}
