@import "../../styles/main.scss";

/*--------------------------------------
 Home.module.scss
--------------------------------------*/
.hold {
  position: relative;
  background-color: #ffffff;
  padding: 20px 50px 30px 50px;
  border-radius: 12px;
  margin-bottom: 15px;
  text-align: center;

  h3 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }
}
