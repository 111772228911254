*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #1f1f1f;
  background: #f6f6f6;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

label {
  font-size: 15px !important;
}

.css-1e061w-MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}

.css-2s8ubb-MuiFormHelperText-root.Mui-error {
  font-size: 11px !important;
  margin: 2px 0 0 0 !important;
}

// Date Range
.react-datepicker-wrapper {
  input {
    padding: 7px;
    border: 1px solid #3f74af;
    border-radius: 4px;
    width: 200px;

    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: #3f74af;
    opacity: 1;
  }

  input::-ms-input-placeholder {
    color: #3f74af;
  }
}

// Heading
.heading {
  margin-bottom: 30px;

  h2 {
    font-size: 42px;
    font-weight: 600;
    color: #000023;
    margin-bottom: 12px;
    text-align: center;
    line-height: 125%;
  }

  p {
    font-size: 17px;
    color: #000023;
    text-align: center;
  }
}
