// Base
@import "./base/variables";
@import "./base/breakpoints";
@import "./base/mixins";
@import "./base/typography";
@import "./base/utils";

// Layout
@import "./layout/drawer";

.main-layout {
  width: 100%;
}
