// Loader.scss

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fcfcff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.filter__loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -25px;
  left: 0;
  z-index: 999999;
}
