@import "../../../../styles/base/breakpoints";

// Expertise
.expertiseStyles {
  padding: 80px 0 60px 0;
  background: #000023;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 50%;
    left: 0;
    height: 50px;
    background: #000023;
    top: -50px;
    border-top-right-radius: 50px;
  }

  &::after {
    position: absolute;
    content: "";
    width: 50%;
    right: 0;
    height: 50px;
    background: #000023;
    top: -50px;
    border-top-left-radius: 50px;
  }

  h2 {
    color: #ffffff;
    text-align: left;
  }

  p {
    color: #ffffff;
    text-align: left;
  }

  .images {
    margin-top: 30px;
  }
}
