@import "../../../styles/main.scss";

// Details.module.scss
.personalInfo {
  display: flex;

  h6 {
    font-size: 1rem;
  }

  p {
    color: $light-black;
    font-size: 0.75rem;
  }

  .icons {
    width: 44px;
    height: 44px;
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-right: 8px;
    @include center-content;
  }
}

.dsaPerformance {
  background-color: $gray-color;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 300ms;
  border: 1px solid $gray-color;

  &:hover {
    border: 1px solid rgba(60, 129, 246, 1);
    background: rgba(60, 129, 246, 0.1);
  }

  h6 {
    font-size: 1rem;
  }

  p {
    color: $light-black;
    font-size: 0.75rem;
  }

  &:hover h6,
  &:hover p {
    color: $secondary-color;
  }
}
